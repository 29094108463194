/* Base styles for card */
.card-nodepage img {
    width: 30%;
    height: auto;
    border-bottom: 1px solid #ccc;
    margin-bottom: 10px;
}

.card-content-nodepage {
    font-size: 1em;
    color: #666;
}

.home-wrapper {
    padding: 20px;
    background-color: #f5f5f5;
}

.custom-tooltip {
    font-size: 12px;
    padding: 5px 10px;
    background-color: rgba(0, 0, 0, 0.8);
    color: #fff;
    border-radius: 5px;
    max-width: 200px;
    text-align: center;
}

.current-path {
    margin-bottom: 20px;
    padding: 10px;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.slider-container {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.slider-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.slider-card {
    padding: 10px;
    cursor: pointer;
    transition: transform 0.3s ease;
}

.slider-card:hover {
    transform: scale(1.05);
}

.card-image {
    width: 100%;
    height: auto;
    border-radius: 10px;
}

.card-title {
    text-align: center;
    margin-top: 10px;
    font-size: 13px;
    font-weight: bold;
}

.spinner {
    width: 40px;
    height: 40px;
    position: relative;
    margin: auto;
}

.double-bounce1,
.double-bounce2 {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: #333;
    opacity: 0.6;
    position: absolute;
    top: 0;
    left: 0;
    animation: bounce 2.0s infinite ease-in-out;
}

.double-bounce2 {
    animation-delay: -1.0s;
}

@keyframes bounce {
    0%,
    100% {
        transform: scale(0.0);
    }
    50% {
        transform: scale(1.0);
    }
}

.file {
    border: 1px solid #ccc;
    padding: 20px;
    cursor: default;
    text-align: center;
    width: 200px;
    margin: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background: #f0f0f0;
    overflow: hidden;
}

.file img {
    width: 50px;
    height: auto;
    margin-bottom: 10px;
}

.file-title {
    font-size: 1.2em;
    font-weight: bold;
    margin: 10px 0;
    color: #333;
}

.user-info {
    display: flex;
    align-items: center;
}

.image-section {
    position: relative;
    height: 630px;
    overflow: hidden;
}

.image-section img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: blur(0.2px);
    transform: scale(1.1);
    margin-top: 20px;
}


.btn-primary {
    display: inline-block;
    padding: 0.75rem 1.25rem;
    font-size: 1rem;
    font-weight: 600;
    color: #fff;
    background-color: #007bff;
    border: none;
    border-radius: 0.25rem;
    text-decoration: none;
    transition: background-color 0.3s ease-in-out;
}

.btn-primary:hover {
    background-color: #0056b3;
}

.card-container-nodepage,
.card-container-morepage {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 54px;
    padding: 20px;
}

.card-nodepage {
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 20px;
    text-align: center;
    width: 240px;
    height: 280px;
    cursor: pointer;
    transition: transform 0.2s;
    word-wrap: break-word;
}

.card-nodepage:hover {
    transform: scale(1.05);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.blog-card {
    border: 2px solid #4caf50;
    background-color: #e8f5e9;
    animation: blogCardAnimation 0.5s ease-in-out;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: left;
    width: 260px;
    height: 300px;
}

@keyframes blogCardAnimation {
    0% {
        transform: scale(0.9);
        opacity: 0.7;
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
}

.blog-image {
    width: 100%;
    height: auto;
    border-bottom: 1px solid #ccc;
    margin-bottom: 10px;
}

.blog-content {
    padding: 10px;
}

.card-title-nodepage {
    font-size: 80%;
    font-weight: bold;
}

.node-details {
    font-size: 1em;
    color: #555;
}

.node-details p {
    word-wrap: break-word;
    font-size: 12px;
}

.read-more-button {
    background-color: #4caf50;
    color: white;
    border: none;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 1em;
    margin-top: 10px;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

.read-more-button:hover {
    background-color: #45a049;
}

.search-bar {
    display: flex;
    align-items: center;
}

.search-input {
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-right: 5px;
}

.search-button {
    background-color: #007bff;
    border: none;
    color: white;
    padding: 5px 10px;
    border-radius: 4px;
    cursor: pointer;
}

.search-button i {
    font-size: 16px;
}

/* Popup */
.popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    border-radius: 10px;
}

.popup-content {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.popup-content p {
    margin-bottom: 20px;
    font-size: 14px;
    color: #333;
}

.popup-content button {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.2s;
}

.popup-content button:hover {
    background-color: #0056b3;
}

/* Responsive Styles */
@media (max-width: 1200px) {
    .hero-section {
        width: 80%;
    }

    .card-nodepage,
    .blog-card {
        width: 200px;
        height: 240px;
    }

    .client-card {
        height: 150px;
        width: 170px;
    }

    .client-img {
        width: 70px;
    }
}

@media (max-width: 768px) {
    .hero-section {
        width: 90%;
        height: 150px;
    }

    .card-nodepage,
    .blog-card {
        width: 180px;
        height: 220px;
    }

    .client-card {
        height: 130px;
        width: 150px;
    }

    .client-img {
        width: 60px;
    }

    .btn-primary {
        padding: 0.5rem 1rem;
        font-size: 0.9rem;
    }

    .section-title {
        font-size: 2rem;
    }
}

@media (max-width: 480px) {
    .hero-section {
        width: 100%;
        height: 120px;
    }

    .card-nodepage,
    .blog-card {
        width: 160px;
        height: 200px;
    }

    .client-card {
        height: 110px;
        width: 130px;
    }

    .client-img {
        width: 50px;
    }

    .btn-primary {
        padding: 0.4rem 0.8rem;
        font-size: 0.8rem;
    }

    .section-title {
        font-size: 1.5rem;
    }
}