/* Base styles for hackathon page */
.navbar-hackathon {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    background-color: #03032e;
    position: fixed;
    width: 100%;
    top: -10px;
    z-index: 1000;
}

.navbar-logo-hackathon {
    height: 40px;
}

.navbar-links-hackathon {
    list-style: none;
    display: flex;
    gap: 20px;
}

.navbar-links-hackathon li {
    display: inline;
}

.navbar-links-hackathon a {
    color: #ffcc33;
    text-decoration: none;
    font-size: 1.2rem;
    transition: color 0.3s;
}

.navbar-links-hackathon a:hover {
    color: #ff6600;
}

.hackathon-container {
    width: 100%;
    text-align: center;
    background-color: #03032e;
    font-family: 'Arial', sans-serif;
    position: relative;
    overflow: hidden;
}

/* Starry Background */
.stars,
.stars2 {
    position: absolute;
    width: 100%;
    height: 100%;
    background: transparent;
    z-index: 0;
}

.stars {
    background: url('https://www.script-tutorials.com/demos/360/images/stars.png') repeat top center;
    animation: animStar 500s linear infinite;
}

.stars2 {
    background: url('https://www.script-tutorials.com/demos/360/images/stars.png') repeat top center;
    animation: animStar 800s linear infinite;
}

@keyframes animStar {
    from {
        background-position: 0 0;
    }
    to {
        background-position: 0 -10000px;
    }
}

/* Header */
.header-hackathon {
    background-color: #1e1e1e;
    color: white;
    padding: 1.5rem;
    font-size: 1.5rem;
    z-index: 1;
}

/* Hero Section */
.hero-hackathon {
    margin-top: 70px;
    height: 30vh;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 1;
}

/* Robot Animation */
@keyframes hop {
    0%,
    100% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-20px);
    }
}

.robot-left,
.robot-right {
    position: absolute;
    width: 100px;
    height: 100px;
    z-index: 2;
    animation: hop 1s infinite;
}

.robot-left {
    left: 5%;
    top: 10%;
}

.robot-right {
    right: 5%;
    top: 10%;
}

.hero-overlay-hackathon {
    color: white;
    padding: 2rem;
    margin-top: 97px;
    border-radius: 10px;
}

.hero-overlay-hackathon h2 {
    font-size: 2.5rem;
    margin-bottom: 1rem;
    margin-top: 36px;
}

/* Registration Section */
.registration-hackathon {
    margin: 2rem 0;
    z-index: 1;
}

.register-btn {
    display: inline-block;
    background-color: #ff6600;
    color: white;
    padding: 0.7rem 1.5rem;
    text-decoration: none;
    border-radius: 5px;
    margin-top: 1rem;
    transition: background 0.3s;
}

.register-btn:hover {
    background-color: #cc5200;
}

/* Sponsors Section */
.sponsors-hackathon {
    background-color: #1e1e1e;
    padding: 2rem 0;
    z-index: 1;
}

.sponsors-hackathon h3 {
    margin-bottom: 1rem;
}

.sponsor-grid {
    display: flex;
    justify-content: center;
    gap: 2rem;
    flex-wrap: wrap;
}

.sponsor-grid img {
    width: 120px;
    height: auto;
}

/* Schedule */
.schedule {
    margin: 2rem 0;
    font-size: 1.2rem;
    z-index: 1;
}

/* Image Section */
.image-section-hackathon {
    position: relative;
    height: 550px;
    width: 100%;
    overflow: hidden;
    z-index: 1;
}

.image-section-hackathon img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: blur(0.2px);
    transform: scale(0.7);
    margin-left: 70px;
    margin-top: 90px;
}

.hero-overlay-hackathon {
    padding: 1rem;
    border-radius: 10px;
}

.hero-overlay-hackathon h1 {
    font-size: 2rem;
    margin-bottom: 0.5rem;
    animation: slideInDown 1s ease-in-out;
}

.hero-overlay-hackathon p {
    font-size: 1rem;
    margin-bottom: 1rem;
    animation: slideInUp 1s ease-in-out;
}

.hero-section-hackathon {
    position: absolute;
    top: 33%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    text-align: center;
    padding: 1rem;
    background: rgba(0, 0, 0, 0.6);
    color: white;
}

/* About Section */
@keyframes blink {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.blink {
    animation: blink 1s infinite;
}

@keyframes slideIn {
    from {
        transform: translateX(-100%);
        opacity: 0;
    }
    to {
        transform: translateX(0);
        opacity: 1;
    }
}

.animate-slide-in {
    animation: slideIn 1s ease-out;
}

.about-hackathon {
    display: flex;
    justify-content: space-between;
    margin: 2rem 0;
    padding: 2rem;
    background-color: #03172e;
    border-radius: 10px;
    z-index: 1;
    margin-top: 100px;
}

.about-left {
    flex: 1;
    padding-right: 1rem;
}

.about-right {
    flex: 2;
    padding-left: 1rem;
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
}

.card {
    padding: 1rem;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    flex: 1 1 calc(33.333% - 1rem);
    box-sizing: border-box;
    transition: transform 0.3s ease, background-color 0.3s ease;
}

.card:hover {
    transform: scale(1.07);
    background-color: #444;
}

.card fieldset {
    border: none;
    padding: 0;
    margin: 0;
}

.card legend {
    font-weight: bold;
    font-size: 1.2rem;
    color: #ff6600;
    margin-top: -30px;
    transition: color 0.3s ease;
}

.card:hover legend {
    color: #ffcc00;
}

.card-content {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.card p {
    margin: 0;
    transition: color 0.3s ease;
}

.card:hover p {
    color: #ffcc00;
}

/* FAQ Section */
.faq-hackathon {
    margin: 2rem 0;
    padding: 2rem;
    background-color: #03172e;
    border-radius: 10px;
    z-index: 1;
}

.faq-grid {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    gap: 1rem;
}

.faq-card {
    background-color: #2e2e2e;
    padding: 1rem;
    border-radius: 10px;
    width: 30%;
    min-width: 250px;
}

.faq-card h3 {
    margin-bottom: 0.5rem;
}

.faq-card p {
    margin: 0;
}

/* Responsive Styles */
@media (max-width: 1200px) {
    .navbar-links-hackathon a {
        font-size: 1rem;
    }

    .hero-overlay-hackathon h2 {
        font-size: 2rem;
    }

    .sponsor-grid img {
        width: 100px;
    }

    .card {
        flex: 1 1 calc(50% - 1rem);
    }

    .faq-card {
        width: 45%;
    }
}
@media (max-width: 768px) {
    .navbar-links-hackathon {
        flex-direction: column;
        gap: 10px;
    }

    .hero-overlay-hackathon h2 {
        font-size: 1.5rem;
    }

    .sponsor-grid img {
        width: 80px;
    }

    .card {
        flex: 1 1 100%;
    }

    .faq-card {
        width: 100%;
    }

    .about-hackathon {
        flex-direction: column;
        align-items: center;
    }

    .about-left,
    .about-right {
        padding: 0;
        width: 100%;
    }
}

@media (max-width: 480px) {
    .navbar-links-hackathon a {
        font-size: 0.9rem;
    }

    .hero-overlay-hackathon h2 {
        font-size: 1.2rem;
    }

    .sponsor-grid img {
        width: 60px;
    }

    .card {
        flex: 1 1 100%;
    }

    .faq-card {
        width: 100%;
    }

    .about-hackathon {
        flex-direction: column;
        align-items: center;
    }

    .about-left,
    .about-right {
        padding: 0;
        width: 100%;
    }
}

/* Footer */
.footer-hackathon {
    background-color: #03172e;
    padding: 1rem;
    color: #fff;
    text-align: center;
    z-index: 1;
}

.footer-hackathon a {
    color: #ff6600;
    text-decoration: none;
    margin: 0 0.5rem;
}

.footer-hackathon a:hover {
    text-decoration: underline;
}

/* Animations */
@keyframes slideInDown {
    from {
        transform: translateY(-100%);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes slideInUp {
    from {
        transform: translateY(100%);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes bounce {
    0%,
    20%,
    50%,
    80%,
    100% {
        transform: translateY(0);
    }
    40% {
        transform: translateY(-30px);
    }
    60% {
        transform: translateY(-15px);
    }
}

@keyframes zoomIn {
    from {
        transform: scale(0);
        opacity: 0;
    }
    to {
        transform: scale(1);
        opacity: 1;
    }
}

.animate-slide-in {
    animation: slideInDown 1s ease-in-out;
}

.animate-fade-in {
    animation: fadeIn 1s ease-in-out;
}

.animate-bounce {
    animation: bounce 2s infinite;
}

.animate-zoom-in {
    animation: zoomIn 1s ease-in-out;
}

/* Additional Animations */
@keyframes fadeInUp {
    from {
        transform: translateY(20px);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes fadeInLeft {
    from {
        transform: translateX(-20px);
        opacity: 0;
    }
    to {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes fadeInRight {
    from {
        transform: translateX(20px);
        opacity: 0;
    }
    to {
        transform: translateX(0);
        opacity: 1;
    }
}

.animate-fade-in-up {
    animation: fadeInUp 1s ease-in-out;
}

.animate-fade-in-left {
    animation: fadeInLeft 1s ease-in-out;
}

.animate-fade-in-right {
    animation: fadeInRight 1s ease-in-out;
}

/* Schedule Cards */
.schedule-card {
    background-color: #2e2e2e;
    padding: 1rem;
    border-radius: 10px;
    margin-bottom: 1rem;
    animation: fadeInUp 1s ease-in-out;
}

/* Roadmap Timeline */
.timeline {
    position: relative;
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem 0;
}

.timeline::after {
    content: '';
    position: absolute;
    width: 6px;
    background-color: #ff6600;
    top: 0;
    bottom: 0;
    left: 50%;
    margin-left: -3px;
}

.timeline-item {
    padding: 1rem 2rem;
    position: relative;
    background-color: inherit;
    width: 50%;
}

.timeline-item::after {
    content: ' ';
    position: absolute;
    width: 25px;
    height: 25px;
    right: -17px;
    background-color: white;
    border: 4px solid #ff6600;
    top: 15px;
    border-radius: 50%;
    z-index: 1;
}

.timeline-item.left {
    left: 0;
}

.timeline-item.right {
    left: 50%;
}

.timeline-item.right::after {
    left: -16px;
}

.timeline-item-content {
    padding: 1rem;
    background-color: #2e2e2e;
    position: relative;
    border-radius: 6px;
    animation: fadeInUp 1s ease-in-out;
}

.timeline-item-content h3 {
    margin-top: 0;
    color: #ff6600;
}

.timeline-item-content p {
    margin: 0;
    color: #fff;
}

.ideas-section {
    margin-top: 40px;
}

.ideas-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    padding: 20px;
}

.idea-card {
    background: white;
    border-radius: 10px;
    padding: 20px;
    text-align: center;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s, box-shadow 0.3s;
    border: 2px solid transparent;
}

.idea-card:hover {
    transform: scale(1.05);
    box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.2);
    border: 2px solid #ffcc33;
}

.idea-card h3 {
    color: #ffcc33;
}

.idea-card .tags {
    margin-top: 10px;
}

.tag {
    background: #ffcc33;
    color: #121212;
    padding: 5px 10px;
    border-radius: 5px;
    margin: 5px;
}

.interaction-buttons {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-top: 10px;
}

.gallery {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 100px;
    margin-left: 50px;
    justify-content: center;
}

.gallery-item {
    flex: 1 1 calc(33.333% - 10px);
    box-sizing: border-box;
    cursor: pointer;
    transition: transform 0.3s, box-shadow 0.3s;
    position: relative;
    overflow: hidden;
    border-radius: 10px;
}

.gallery-item:hover {
    transform: scale(1.05);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.gallery-item img {
    width: 100%;
    height: auto;
    display: block;
    transition: transform 0.3s;
}

.gallery-item:hover img {
    transform: scale(1.1);
}

.modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 1000;
    opacity: 0;
    animation: fadeIn 0.5s forwards;
}

.modal-content {
    max-width: 90%;
    max-height: 90%;
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    transition: transform 0.3s;
    animation: slideIn 0.5s forwards;
}

.modal-content img {
    max-width: 100%;
    max-height: 80vh;
}

.close {
    position: absolute;
    top: 20px;
    right: 30px;
    color: #fff;
    font-size: 40px;
    font-weight: bold;
    cursor: pointer;
}

.caption {
    margin-top: 10px;
    color: #000;
    text-align: center;
}

/* Responsive Styles */
@media (max-width: 1200px) {
    .navbar-links-hackathon a {
        font-size: 1rem;
    }

    .hero-overlay-hackathon h2 {
        font-size: 2rem;
    }

    .sponsor-grid img {
        width: 100px;
    }

    .card {
        flex: 1 1 calc(50% - 1rem);
    }

    .faq-card {
        width: 45%;
    }
}

@media (max-width: 768px) {
    .navbar-links-hackathon {
        flex-direction: column;
        gap: 10px;
    }

    .hero-overlay-hackathon h2 {
        font-size: 1.5rem;
    }

    .sponsor-grid img {
        width: 80px;
    }

    .card {
        flex: 1 1 100%;
    }

    .faq-card {
        width: 100%;
    }

    .about-hackathon {
        flex-direction: column;
        align-items: center;
    }

    .about-left,
    .about-right {
        padding: 0;
        width: 100%;
    }
}

@media (max-width: 480px) {
    .navbar-links-hackathon a {
        font-size: 0.9rem;
    }

    .hero-overlay-hackathon h2 {
        font-size: 1.2rem;
    }

    .sponsor-grid img {
        width: 60px;
    }

    .card {
        flex: 1 1 100%;
    }

    .faq-card {
        width: 100%;
    }

    .about-hackathon {
        flex-direction: column;
        align-items: center;
    }

    .about-left,
    .about-right {
        padding: 0;
        width: 100%;
    }

    .timeline-item {
        width: 100%;
        padding-left: 30px;
        padding-right: 25px;
    }

    .timeline-item.left::after,
    .timeline-item.right::after {
        left: 50%;
        margin-left: -12px;
    }

    .timeline-item.right {
        left: 0;
    }

    .timeline::after {
        left: 50%;
        margin-left: -3px;
    }

    .ideas-grid {
        grid-template-columns: 1fr;
    }

    .gallery-item {
        flex: 1 1 100%;
    }
}