/* Base styles for card */
.card-morepage {
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 20px;
    text-align: center;
    width: 100%;
    max-width: 400px;
    height: auto;
    cursor: pointer;
    transition: transform 0.2s;
    margin: 10px auto;
}

.card-morepage:hover {
    transform: scale(1.05);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.card-title-morepage {
    margin-top: 10px;
    font-size: 16px;
    font-weight: bold;
    color: black;
    text-decoration: none;
}

/* Base styles for back button */
.back-button {
    margin-top: 82px;
    margin-left: 29px;
    margin-bottom: 10px;
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.back-button:hover {
    background-color: #0056b3;
}

.card-link {
    text-decoration: none;
    color: inherit;
}

.card-link:hover {
    text-decoration: none;
}

/* Responsive Styles */
@media (min-width: 1200px) {
    .card-morepage {
        max-width: 600px;
    }

    .back-button {
        margin-left: 50px;
    }
}

@media (max-width: 768px) {
    .card-morepage {
        max-width: 90%;
        padding: 15px;
    }

    .card-title-morepage {
        font-size: 14px;
    }

    .back-button {
        margin-top: 60px;
        margin-left: 20px;
        padding: 8px 16px;
    }
}

@media (max-width: 480px) {
    .card-morepage {
        max-width: 100%;
        padding: 10px;
    }

    .card-title-morepage {
        font-size: 12px;
    }

    .back-button {
        margin-top: 50px;
        margin-left: 10px;
        padding: 6px 12px;
    }
}