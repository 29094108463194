/* Header */
.header {
  font-size: 2.8rem;
  color: #ffcc33;
  text-align: center;
  margin-bottom: 40px;
  animation: fadeIn 1s ease-in-out;
}

/* Winners Section */
.winners-section h2 {
  margin: 20px 0;
  text-align: center;
  color: #ffcc33;
}
.winner-card {
  background: linear-gradient(135deg, #ffcc33, #ff6600);
  color: white;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  font-size: 1.2rem;
  transition: transform 0.3s, box-shadow 0.3s;
}
.winner-card:hover {
  transform: scale(1.05);
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.2);
}

/* Hackathon Ideas */
.ideas-section {
  margin-top: 40px;
}
.ideas-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* 2 columns */
  gap: 20px;
  padding: 20px;
}
.idea-card {
  background: white;
  border-radius: 10px;
  padding: 20px;
  text-align: center;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  transition: transform 0.3s, box-shadow 0.3s;
  border: 2px solid transparent; /* Initial border */
}
.idea-card:hover {
  transform: scale(1.05); /* Slightly enlarge on hover */
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.2); /* More pronounced shadow on hover */
  border: 2px solid #ffcc33; /* Border color on hover */
}
.idea-card h3 {
  color: #ffcc33;
}
.idea-card .tags {
  margin-top: 10px;
}
.tag {
  background: #ffcc33;
  color: #121212;
  padding: 5px 10px;
  border-radius: 5px;
  margin: 5px;
}
.interaction-buttons {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 10px;
}
.interaction-buttons button {
  background: #ffcc33;
  color: black;
  border: none;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s;
}
.interaction-buttons button:hover {
  background: #ff6600;
}

/* Leaderboard */
.leaderboard-section h2 {
  margin-top: 40px;
  text-align: center;
  color: #ffcc33;
}
.leaderboard {
  display: flex;
  justify-content: center;
  gap: 15px;
}
.leaderboard-item {
  background: rgba(255, 255, 255, 0.1);
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(255, 255, 255, 0.1);
}
.rank {
  font-size: 1.5rem;
  font-weight: bold;
  color: #ffcc33;
}

/* Call to Action */
.cta-section {
  margin-top: 40px;
  text-align: center;
}
.cta-button {
  background: linear-gradient(135deg, #ffcc33, #ff6600);
  color: black;
  padding: 15px 30px;
  border: none;
  font-size: 1.2rem;
  cursor: pointer;
  border-radius: 50px;
  transition: all 0.3s;
}
.cta-button:hover {
  background: linear-gradient(135deg, #ff6600, #ffcc33);
}
  
  /* Animations */
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }