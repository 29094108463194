/* Base styles for header */
header {
    background-color: #f8f9fa;
    border-bottom: 1px solid #ddd;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

/* Animation for blog cards */
@keyframes blogCardAnimation {
    0% {
        transform: scale(0.9);
        opacity: 0.7;
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
}

/* Base styles for navbar */
.navbar-main {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

ul.pl-4 {
    padding-left: 20px;
}

.center-aligned-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 0;
}

.center-aligned-list li {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
    text-align: left;
}

.main-container {
    margin-top: 7px;
}

.navbar-brand1 {
    font-size: 14px;
    font-weight: bold;
    color: #333;
    margin: 0 20px;
}

.navbar-brand1:hover {
    color: #007bff;
    text-decoration: none;
}

.navbar-nav {
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
    list-style: none;
}

.search-bar {
    display: flex;
    align-items: center;
    margin-left: auto;
    margin-right: 1rem;
}

.search-bar input {
    padding: 5px 10px;
    font-size: 14px;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin-right: 8px;
    width: 200px;
}

.search-bar button {
    background: #007bff;
    color: #fff;
    border: none;
    padding: 5px 10px;
    border-radius: 4px;
    cursor: pointer;
}

.search-bar button:hover {
    background: #0056b3;
}

.theme-button,
.login-button {
    background: #007bff;
    color: #fff;
    border: none;
    padding: 5px 10px;
    border-radius: 4px;
    cursor: pointer;
    margin-left: 10px;
}

.theme-button:hover,
.login-button:hover {
    background: #0056b3;
}

/* Responsive Styles */
@media (min-width: 1200px) {
    .navbar-brand1 {
        font-size: 16px;
    }

    .search-bar input {
        width: 250px;
    }

    .theme-button,
    .login-button {
        padding: 8px 15px;
    }
}

@media (max-width: 768px) {
    .navbar-brand1 {
        font-size: 12px;
    }

    .search-bar input {
        width: 150px;
    }

    .theme-button,
    .login-button {
        padding: 5px 10px;
    }

    .center-aligned-list {
        flex-direction: row;
        justify-content: space-around;
    }

    .center-aligned-list li {
        width: auto;
    }
}

@media (max-width: 480px) {
    .navbar-brand1 {
        font-size: 10px;
    }

    .search-bar input {
        width: 100px;
    }

    .theme-button,
    .login-button {
        padding: 4px 8px;
    }

    .center-aligned-list {
        flex-direction: column;
    }

    .center-aligned-list li {
        width: 100%;
    }
}