.loader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100px;
}

.loader .dots {
  display: flex;
  justify-content: space-between;
  width: 60px;
}

.loader .dot {
  width: 15px;
  height: 15px;
  background-color: #007bff;
  border-radius: 50%;
  animation: bounce 1.5s infinite ease-in-out;
}

.loader .dot:nth-child(1) {
  animation-delay: -0.32s;
}

.loader .dot:nth-child(2) {
  animation-delay: -0.16s;
}

