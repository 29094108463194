/* Base styles for chat window */
.chat-window {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 90%;
  max-width: 400px;
  height: 70%;
  max-height: 500px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  display: flex;
  flex-direction: column;
  font-family: Arial, sans-serif;
  transition: all 0.3s ease;
}

/* Styles for larger screens */
@media (min-width: 768px) {
  .chat-window {
    width: 350px;
    height: 500px;
  }
}

/* Styles for very small screens */
@media (max-width: 480px) {
  .chat-window {
    width: 100%;
    height: 60%;
    bottom: 0;
    right: 0;
    border-radius: 0;
  }
}

.chat-header {
  background-color: #007bff;
  color: white;
  padding: 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.chat-header h4 {
  margin: 0;
  font-size: 1.2rem;
}

.close-button {
  background: none;
  border: none;
  color: white;
  font-size: 20px;
  cursor: pointer;
}

.chat-body {
  flex: 1;
  padding: 10px;
  overflow-y: auto;
  background-color: #f9f9f9;
  scroll-behavior: smooth;
}

.chat-message {
  margin-bottom: 10px;
  padding: 10px 15px;
  border-radius: 20px;
  max-width: 80%;
  font-size: 0.9rem;
  line-height: 1.4;
  animation: fadeIn 0.3s ease;
}

.chat-message.user {
  background-color: #007bff;
  color: white;
  align-self: flex-end;
}

.chat-message.bot {
  background-color: #e1e1e1;
  color: black;
  align-self: flex-start;
}

.chat-footer {
  display: flex;
  padding: 10px;
  border-top: 1px solid #ccc;
  background-color: #fff;
}

.chat-footer input {
  flex: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 20px;
  margin-right: 10px;
  font-size: 0.9rem;
}

.chat-footer button {
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 20px;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 0.9rem;
  transition: background-color 0.3s;
}

.chat-footer button:hover {
  background-color: #0056b3;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}