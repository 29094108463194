/* Base styles for breadcrumb */
.breadcrumb {
    display: flex;
    margin-bottom: 10px;
    margin-top: 82px;
    margin-left: 16px;
    font-size: 16px;
    flex-wrap: wrap;
}

.breadcrumb span {
    cursor: pointer;
    color: blue;
    background-color: #ececec;
    padding: 5px 10px;
    border-radius: 5px;
    margin-right: -10px;
    margin-bottom: 5px;
}

.breadcrumb span:hover {
    text-decoration: none;
    background-color: #e0e0e0;
}

.breadcrumb-text {
    text-transform: uppercase;
}

/* Styles for larger screens */
@media (min-width: 768px) {
    .breadcrumb {
        font-size: 18px;
    }

    .breadcrumb span {
        padding: 8px 12px;
    }
}

/* Styles for very small screens */
@media (max-width: 480px) {
    .breadcrumb {
        font-size: 14px;
        margin-top: 60px;
        margin-left: 10px;
    }

    .breadcrumb span {
        padding: 4px 8px;
    }
}