
/* Services */
.section-title {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 1.5rem;
}

.service-card {
    border: 1px solid #e1e1e1;
    border-radius: 8px;
    padding: 2rem;
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.service-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* Clients */
.client-card {
    border: 4px solid #e1e1e1;
    padding: 1.5rem;
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    background-color: #f8f9fa;
    border-radius: 50%;
    height: 170px;
    width: 220px;
    margin-bottom: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.client-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.client-img {
    width: 90px;
    height: auto;
    object-fit: cover;
    transition: transform 0.3s ease-in-out;
}

.client-img:hover {
    transform: scale(1.1);
}

/* Footer */
footer {
    background-color: #333;
    color: white;
}

footer a {
    text-decoration: none;
    font-weight: bold;
    color: #f8f9fa;
}

footer a:hover {
    color: #adb5bd;
}

/* Animations */
@keyframes slideInDown {
    from {
        transform: translateY(-100%);
    }
    to {
        transform: translateY(0);
    }
}

@keyframes slideInUp {
    from {
        transform: translateY(100%);
    }
    to {
        transform: translateY(0);
    }
}


/* Mission and Vision Section */
#mission-vision .section-title {
    font-size: 2.5rem;
    margin-bottom: 40px;
    color: #333;
    text-align: center;
    font-family: 'Roboto', sans-serif;
}

.mission-vision-section {
    padding: 20px 0;
    height: auto;
    width: 100%;
    margin-left: 17px;
    background-color: #f9f9f9;
    border-radius: 10px;
}

.mission-vision-content {
    background-color: #ffffff;
    border: 1px solid #dee2e6;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    border-radius: 10px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.mission-vision-content:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

#mission-vision p {
    font-size: 1.2rem;
    line-height: 1.6;
    color: #555;
    font-family: 'Open Sans', sans-serif;
}

/* Chat Icon Button */
.chat-icon-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    border-radius: 50%;
    padding: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    z-index: 1000;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.chat-icon-button:hover {
    transform: scale(1.1);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.chat-icon-button img {
    width: 50px;
    height: 50px;
}

/* Image Section */
.image-section {
    position: relative;
    height: 630px;
    overflow: hidden;
    border-radius: 10px;
}

.image-section img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: blur(0.2px);
    transform: scale(1.1);
    transition: transform 0.3s ease;
}

.image-section img:hover {
    transform: scale(1.05);
}

/* Responsive Styles */
@media (max-width: 1200px) {
    .mission-vision-section {
        width: 90%;
        margin-left: 5%;
    }
}

@media (max-width: 768px) {
    #mission-vision .section-title {
        font-size: 2rem;
    }

    #mission-vision p {
        font-size: 1rem;
    }

    .mission-vision-section {
        width: 100%;
        margin-left: 0;
    }

    .chat-icon-button {
        bottom: 15px;
        right: 15px;
    }

    .chat-icon-button img {
        width: 40px;
        height: 40px;
    }

    .image-section {
        height: 400px;
    }
}

@media (max-width: 480px) {
    #mission-vision .section-title {
        font-size: 1.5rem;
    }

    #mission-vision p {
        font-size: 0.9rem;
    }

    .mission-vision-section {
        width: 100%;
        margin-left: 0;
    }

    .chat-icon-button {
        bottom: 10px;
        right: 10px;
    }

    .chat-icon-button img {
        width: 30px;
        height: 30px;
    }

    .image-section {
        height: 300px;
    }
}




 /* Carousel Styles */
 .carousel-item {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .carousel-item .col-md-4 {
    padding: 15px;
  }
  
  .carousel-control-prev-icon,
  .carousel-control-next-icon {
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    padding: 10px;
  }
  
  .carousel-indicators li {
    color: black;
    background-color: black !important;
  }
  
  .carousel-indicators .active {
    background-color: gray !important;
  }
 

  
.hero-section {
    height: 180px;
    width: 600px;
    position: absolute;
    top: 26%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    text-align: center;
    padding: 1rem;
    background: rgba(0, 0, 0, 0.6);
    color: white;
}

.hero-overlay {
    padding: 1rem;
    border-radius: 10px;
}

.hero-overlay h1 {
    font-size: 2rem;
    margin-bottom: 0.5rem;
    animation: slideInDown 1s ease-in-out;
}

.hero-overlay p {
    font-size: 1rem;
    margin-bottom: 1rem;
    animation: slideInUp 1s ease-in-out;
}